// Set variables here
$primary: #056839;
$secondary: rgba(0, 0, 0, 0.3);
$warning: #E9983C;
$default: #939598 ;
$light: #efefef;
$dark: #4D4D4D;
$info: #0ACC70;

// custom colors
$light-green: #0ACC70;

$primary-white: #FFFFFF;
$primary-black: #000000;

$input-btn-focus-width:       1px;